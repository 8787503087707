import { getHighBondUrlParts } from '@acl-services/highbond-common';
import { envFlippers } from './Flippers';
import { getEnvironmentName, isDiligentOneEnvironment } from './environments';

export default function appConfig(appOrigin: string) {
  const { protocol, region, domain, appSubdomain, domainSuffix, tld } = getHighBondUrlParts(appOrigin);
  const rootDomain = `${domain}${domainSuffix}.${tld}`;
  const regionSuffix = region !== 'us' ? `-${region}` : '';
  const accountsUrl = `${protocol}://accounts.${rootDomain}`;
  const environmentName = getEnvironmentName(rootDomain);
  const flippers = envFlippers[environmentName];
  const isDiligentOneDomain = isDiligentOneEnvironment(rootDomain);

  return {
    globalNavBarApiUrl: (orgId, userId) => {
      if (flippers.newNavBarApi) {
        // this uses the new global nav bar api
        return `${accountsUrl}/api/v1/orgs/${orgId}/users/${userId}/global-nav-bar`;
      }
      // returns the old nav bar api
      return `${protocol}://apis${regionSuffix}.${rootDomain}/accounts/api/global_nav_bar?org_id=${orgId}`;
    },
    loginUrl: `${accountsUrl}/login`,
    platformComponentsUrl: `${protocol}://platform-components.${rootDomain}/assets/presentation.json`,
    sessionUrl: `${protocol}://authy-api${regionSuffix}.${rootDomain}/session`,
    tokenRefreshUrl: `${accountsUrl}/api/token/refresh`,
    getUsersUrl: ({ orgId }) => `${accountsUrl}/orgs/${orgId}/users`,
    getLicensesUrl: ({ orgSubdomain }) => `${protocol}://${orgSubdomain}.licenses${regionSuffix}.${rootDomain}`,
    getProfileSettingsUrl: ({ orgId, userId }) => `${accountsUrl}/orgs/${orgId}/users/${userId}`,
    getGroupsUrl: ({ orgId }) => `${accountsUrl}/orgs/${orgId}/groups`,
    getActivationsUrl: ({ orgId }) => `${accountsUrl}/orgs/${orgId}/activations`,
    getActivityLogsUrl: ({ orgId }) => `${accountsUrl}/orgs/${orgId}/activities`,
    getConfigurationUrl: ({ orgSubdomain }) =>
      `${protocol}://${orgSubdomain}.configuration${regionSuffix}.${rootDomain}`,
    getOrganizationUrl: ({ orgId }) => `${accountsUrl}/orgs/${orgId}/details`,
    getGovMapsUrl: ({ orgSubdomain }) => `${protocol}://${orgSubdomain}.org-structure${regionSuffix}.${rootDomain}`,
    lastVisitedOrgApiUrl: (userId: string) => `${accountsUrl}/api/v1/users/${userId}/last-visited-org`,
    getLandingPageUrl: ({ orgSubdomain }) => `${protocol}://${orgSubdomain}.home${regionSuffix}.${rootDomain}`,
    rootDomain,
    appSubdomain,
    environmentName,
    flippers,
    isDiligentOneDomain,
  };
}
